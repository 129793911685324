<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.02)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                background-color="#fff"
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                @change="venueServiceChange"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.facilityId"
                outlined
                background-color="#fff"
                placeholder="Facilities"
                item-value="id"
                item-text="name"
                :items="facilities"
                @change="getReport"
                multiple
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
                <template
                  v-if="searchParams.facilityId.length == facilities.length"
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Facilities</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, {{ item.name }}</span>
                  <span v-if="index == 2">, ...</span>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          searchParams.facilityId.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ icon() }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                placeholder="Type"
                v-model="searchParams.type"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Trainer', value: 'trainer' },
                  { name: 'Academy', value: 'academy' },
                  { name: 'Facility', value: 'facility' },
                ]"
                @change="getReport"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col md="2" v-if="searchParams.type != 'facility'">
              <v-select
                :items="trainers"
                placeholder="Trainer*"
                item-text="name"
                item-value="id"
                v-model="searchParams.trainerId"
                outlined
                @change="getReport"
                background-color="#fff"
                multiple
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
                <template
                  v-if="
                    searchParams.trainerId &&
                    searchParams.trainerId.length == trainers.length
                  "
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Trainers</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>
            <v-col md="2">
              <v-name-search
                label=""
                outlined
                v-model="searchParams.nameSearch"
                @updateCustomer="nameSearch"
                required
                class-name="q-text-field shadow-0"
                :dense="true"
                :clearable="true"
              ></v-name-search>
            </v-col>
            <v-col cols="2">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                placeholder="Type"
                v-model="searchParams.attendance"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'All', value: null },
                  { name: 'Attended', value: 'attended' },
                  { name: 'Not Attended', value: 'not_attended' },
                ]"
                @change="getReport"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date2Formatted"
                    outlined
                    background-color="#fff"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default shadow-0 rounded-5">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Attendance Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table table-striped header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Timestamp
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type == 'academy'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Academy Name
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-else-if="searchParams.type == 'trainer'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Package Name
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type == 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Service</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Ground Assignment
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Start Time
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">End Time</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Duration (hr)
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type != 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Trainer Name
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type != 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Trainer Revenue(Inc.VAT)
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Attendance</div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type == 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Scanner</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type == 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Checked In Time
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type == 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Checked Out Time
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="attendanceReport.length > 0"
                        :set="((GrandTotalHours = 0), (GrandTotalRevenue = 0))"
                      >
                        <template v-for="(details, index) in attendanceReport">
                          <tr
                            v-if="details.type == 'total'"
                            :key="`obj_head_${index}`"
                            class="grand_total_bg"
                          >
                            <td
                              :colspan="searchParams.type == 'facility' ? 4 : 3"
                            ></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="2"
                              :set="
                                (GrandTotalHours += parseFloat(details.hours))
                              "
                            >
                              <div class="md-table-cell-container">
                                Total Hours:{{
                                  parseFloat(details.hours).toFixed(2)
                                }}
                              </div>
                            </td>
                            <td
                              :colspan="searchParams.type == 'facility' ? 7 : 2"
                            ></td>
                            <td
                              v-if="searchParams.type != 'facility'"
                              colspan="2"
                              class="md-table-cell-container text-center font-weight-black"
                              :set="
                                (GrandTotalRevenue += parseFloat(
                                  details.trainer_revenue
                                ))
                              "
                            >
                              Total revenue:
                              {{
                                parseFloat(details.trainer_revenue).toFixed(2)
                              }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                          <tr
                            v-else-if="details.type == 'data'"
                            :key="`obj_head_${index}`"
                          >
                            <td class="md-table-cell-container text-center">
                              <div class="md-table-cell-container text-center">
                                {{ details.date }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'academy'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ details.workshop_name }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'trainer'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ details.package_name || "NA" }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'facility'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ details.service_name }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  details.facility_name
                                    ? details.facility_name
                                    : "NA"
                                }}
                                - {{ details.service_name }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.customer_name }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.start_time | timeFormat }}
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.end_time | timeFormat }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ parseFloat(details.hours).toFixed(2) || 0 }}
                                hr
                              </div>
                            </td>

                            <td
                              class="md-table-cell"
                              v-if="searchParams.type != 'facility'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ details.trainer_name }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type != 'facility'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{
                                  details.trainer_revenue
                                    ? parseFloat(details.trainer_revenue)
                                    : 0 | toCurrency
                                }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.attendance }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'facility'"
                            >
                              <div class="md-table-cell-container text-center">
                                {{
                                  details.check_in_time
                                    ? details.scanner_name
                                    : "-"
                                }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'facility'"
                            >
                              <div
                                class="md-table-cell-container text-center"
                                v-if="
                                  details.check_in_time &&
                                  details.check_in_time != null
                                "
                              >
                                {{ details.check_in_time | timeStamp }}
                              </div>
                              <div
                                class="md-table-cell-container text-center"
                                v-else
                              >
                                {{ "NA" }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="searchParams.type == 'facility'"
                            >
                              <div
                                class="md-table-cell-container text-center"
                                v-if="
                                  details.check_out_time &&
                                  details.check_out_time != null
                                "
                              >
                                {{ details.check_out_time | timeStamp }}
                              </div>
                              <div
                                class="md-table-cell-container text-center"
                                v-else
                              >
                                {{ "NA" }}
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template>
                          <tr
                            :key="`obj_head_${index}`"
                            class="grand_total_bg sticky_bottom"
                          >
                            <td
                              :colspan="searchParams.type == 'facility' ? 8 : 3"
                            ></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="2"
                            >
                              <div class="md-table-cell-container">
                                GRAND TOTAL HOURS:{{
                                  parseFloat(GrandTotalHours).toFixed(2)
                                }}
                              </div>
                            </td>
                            <td
                              :colspan="searchParams.type == 'facility' ? 7 : 2"
                            ></td>
                            <td
                              v-if="searchParams.type != 'facility'"
                              class="md-table-cell-container text-center font-weight-black"
                              colspan="2"
                            >
                              GRAND TOTAL REVENUE:
                              {{ currencyCode }} {{ GrandTotalRevenue }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="searchParams.type == 'academy' ? 9 : 8">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      attendanceReport: [],
      menu1: null,
      menu2: null,
      fileName: null,
      searchParams: {
        type: "academy",
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceId: null,
        facilityId: [],
        trainerId: null,
        attendance: null,
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      facilities: [],
      trainers: [],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    this.onResize();
    this.getFacilities();
    this.getTrainers();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.searchParams.facilityId.length == this.facilities.length) {
          this.searchParams.facilityId = [];
        } else {
          this.searchParams.facilityId = this.facilities.map((item) => item.id);
        }
      });
      setTimeout(() => {
        this.getReport();
      });
    },
    icon() {
      if (this.searchParams.facilityId.length == this.facilities)
        return "mdi-close-box";
      if (this.searchParams.facilityId.length == 0)
        return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?type=${this.searchParams.type}&from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}&attendance=${this.searchParams.attendance}`;
      this.fileName =
        this.searchParams.type.charAt(0).toUpperCase() +
        this.searchParams.type.slice(1) +
        "-" +
        this.searchParams.from_date +
        "-to-" +
        this.searchParams.to_date;
      let venueService = null;
      let Facility = null;
      let Trainer = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }
      if (this.searchParams.trainerId) {
        url += `&trainer_ids=${this.searchParams.trainerId}`;
        Trainer = this.findValueByKey(
          this.trainers,
          this.searchParams.trainerId
        );
      }
      if (this.searchParams.facilityId == "All") {
        delete this.searchParams.facilityId;
      } else if (typeof this.searchParams.facilityId != "undefined") {
        url += `&facility_ids=${this.searchParams.facilityId}`;
        Facility = this.findValueByKey(
          this.facilities,
          this.searchParams.facilityId
        );
      }

      if (this.searchParams.customer_id) {
        url += `&customer_id=${this.searchParams.customer_id}`;
      }

      if (venueService !== null) this.fileName += "-" + venueService.name;
      if (Facility !== null) {
        let multiple = "";
        if (this.searchParams.facilityId.length > 1) {
          multiple = "...";
        }
        let facilityName = Facility.name + multiple;
        this.fileName += "-" + facilityName;
      }
      if (Trainer !== null) {
        let multiple = "";
        if (this.searchParams.trainerId.length > 1) {
          multiple = "...";
        }
        let trainerName = Trainer.name + multiple;
        this.fileName += "-" + trainerName;
      }
      if (this.searchParams.customer_name != undefined) {
        this.fileName += "-" + this.searchParams.customer_name;
        url += `&customer_name=${this.searchParams.customer_name}`;
      }
      if (this.fileName != null)
        this.fileName = "Attendance-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "Attendance-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },
    venueServiceChange() {
      this.getFacilities();
      this.getReport();
    },
    getFacilities() {
      let facility_parameter_url = "";
      if (this.searchParams.venueServiceId != null) {
        facility_parameter_url =
          "?venue_service_id=" +
          this.searchParams.venueServiceId.venue_service_id;
      }
      this.$http
        .get(`venues/facilities/short` + facility_parameter_url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.facilities = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getTrainers() {
      this.$http
        .get(`venues/trainers/short`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainers = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/attendance-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            const customerAttendance = data;
            this.attendanceReport = [];
            let hours = 0;
            let trainerRevenue = 0;
            customerAttendance.forEach((log, index) => {
              if (index == 0) {
                this.attendanceReport.push({
                  date: log.date,
                  type: "date",
                });
              }
              if (
                index > 0 &&
                customerAttendance[index - 1].date !=
                  customerAttendance[index].date
              ) {
                this.attendanceReport.push({
                  hours: hours.toFixed(3),
                  trainer_revenue: trainerRevenue.toFixed(3),
                  type: "total",
                });
                hours = 0;
                trainerRevenue = 0;
                this.attendanceReport.push({
                  date: log.date,
                  type: "date",
                });
              }
              hours = hours + parseFloat(log.hours);
              if (log.attendance != "Unmarked") {
                trainerRevenue =
                  trainerRevenue + parseFloat(log.trainer_revenue);
              }
              this.attendanceReport.push({
                date: log.date,
                start_time: log.start_time,
                end_time: log.end_time,
                hours: log.hours,
                attendance: log.attendance,
                scheduled_sessions: log.scheduled_sessions,
                trainer_name: log.trainer_name,
                customer_name: log.customer_name,
                trainer_revenue: log.trainer_revenue,
                service_name: log.service_name,
                facility_name: log.facility_name,
                workshop_name: log.workshop_name,
                package_name: log.package_name,
                scanner_name: log.scanner_name,
                check_in_time: log.check_in_time,
                check_out_time: log.check_out_time,
                type: "data",
              });
            });
            if (data.length > 0) {
              this.attendanceReport.push({
                hours: hours.toFixed(3),
                trainer_revenue: trainerRevenue.toFixed(3),
                type: "total",
              });
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(report_type) {
      let link = "";
      if (report_type == "pdf") {
        link = "attendance-report-pdf";
      } else if (report_type == "excel") {
        link = "attendance-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, report_type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
    nameSearch(e) {
      this.searchParams.customer_id = e.customer_id;
      this.searchParams.customer_name = e.name;
      this.getReport();
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
</style>
